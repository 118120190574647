@media (max-width:450px) {



    .userProfilePage {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }

    .userProfileBody {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 0 1rem;
        gap: 1.5rem;
        /* border: 2px solid red; */
    }

    .userProfileBody * {

        /* border: 2px solid red; */
    }

    .userProfileImageSection {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.7rem;
    }


    .userProfileImageSection img {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
    }

    .userProfileDetailsCard {
        width: 100%;
        border-radius: 6px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        box-shadow: 0 0 27px -12px gray;
    }

    .userProfileId {
        width: 100%;
        text-align: center;
        font-weight: 600;
        color: var(--commonColor);
        font-size: 16px;

    }

    .userAnotherDetails {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
    }

    .userAnotherDetails tbody {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;

    }

    .userAnotherDetails tbody tr {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 3rem;

    }

    /* .userAnotherDetails tbody tr td { */
    /* } */

    .userAnotherDetails tbody tr td:first-child {
        color: var(--commonColor);
        width: fit-content;
    }

    .userAnotherDetails tbody tr td:last-child {
        font-size: 18px;
        flex: 1;
        text-align: right;

    }

    .userAnotherDetails tbody tr td:last-child a {
        text-decoration: none;
        color: black;
    }
.userProfileShortCard{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.totalWaterCan{
    width: 100%;
}
.userProfileStatusSecondCard{
    width: 100%;
    border-radius: 6px;
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 27px -12px gray;
    position: relative;
    gap: 3px;
}
.userProfileStatusSecondCard::after{
    content: "";
    position: absolute;
    left: 0;
    height: 60%;
    width: 100%;
    z-index: -1;
    background-color: var(--commonColor);
}

.userProfileTotalCardInner{
    width: 50%;
    /* height: 4rem; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: white;
}
.userProfileInnerCardDigits{
 display: flex;
 flex-direction: row;
 align-items: flex-end;
 gap: 0.5rem;
}
.userProfileTotalCardInner label{
    font-size: 19px;
    color: gray;
    font-weight: 600;
}
.userProfileInnerCardDigits span:first-child{
    font-size: 46px;
    font-weight: 600;
    color: var(--loginBackColor);
}
.userProfileInnerCardDigits span:last-child{
    font-size: 30px;
    color: var(--loginBackColor);
    background-color: white;
}
.userProfileFooterSec{
    width: 100%;
    height: 20rem;
    overflow: hidden;
    border-radius: 13px;
    margin-bottom: 2rem;
}
.userProfileFooterSec img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


}