* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media (min-width:450px) {
  body {
    display: none;
  }
}


:root {
  --loginBackColor: #0077FF;
  --loginInputTxt: #004CA3;
  --commonColor:#004CA3;

}

button:disabled {
  background-color: gray;
  cursor: pointer;
}

/* universal classes  */
.submitBtn {
  background-color: #004CA3 !important;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.g-1 {
  gap: 0.5rem;
}

.g-2 {
  gap: 1rem;

}

.g-3 {
  gap: 1.5rem;

}