@media (max-width:450px) {
    .userHomePage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 0.8rem;
    }

    .userProfileSection {
        width: 100%;
        height: 6rem;
        padding: 0 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .profileContents {
        flex: 1;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
    }

    .userProfileImg {
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 50%;
        border: 2px solid gray;
    }

    .userProfileDataSec {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .userProfileDataSec h2 {
        font-size: 23px;
    }

    .userProfileDataSec .userProfileAddress {
        color: var(--commonColor);
    }






    .profileEditBtnSec {
        width: 4.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .userProfileEditBtn {
        background: none;
        border: none;
        outline: none;
    }

    /* user profile banner style */
    .userProfileBanner {
        width: 100%;
        height: 10rem;
        overflow: hidden;
        padding: 0 1rem;
    }

    .userProfileBanner img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        box-shadow: 0 0 27px -12px gray;
        object-fit: cover;
    }

    .paymentsAndCount {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
    }

    .CountAndPaymentCards {
        width: 48%;
        height: 11rem;
        border-radius: 6px;
        box-shadow: 0 0 27px -12px gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        overflow: hidden;
    }

    .cardTitle {
        padding-top: 7px;
        font-size: 20px;
        font-weight: 700;
        color: gray;
    }

    .cardDetails {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        text-align: center;
        gap: 0.5rem;
    }

    .cardDetailsNumber {
        font-size: 4.5rem;
        color: #0077FF;
    }

    .cardDetailsUnit {
        font-size: 2.2rem;
        color: #0077FF;
    }

    .cardDate {
        width: 100%;
        height: 2.5rem;
        background-color: var(--loginBackColor);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 18px;
    }


    /* total of water can and total price  */
    .totalWaterCan {
        width: 100%;
        padding: 1rem 1rem;

    }

    .totalWaterCan .totalWaterCanCard {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        box-shadow: 0 0 27px -12px gray;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        position: relative;
        gap: 2px;
        overflow: hidden;
        z-index: -1;
    }

    .totalWaterCanCard::before {
        content: "";
        position: absolute;
        height: 4rem;
        width: 50%;
        background-color: var(--loginBackColor);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        left: 30%;
    }

    .totalWaterCanDetails {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: white;
    }

    .totalWaterCanDetails label {
        font-size: 21px;
        color: gray;
    }

    .totalWaterCanDetails .waterCanCount {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        gap: 0.5rem;
    }

    .totalWaterCanDetails .numberOfWaterCan {
        font-size: 2.5rem;
        color: var(--loginBackColor) !important;
        font-weight: 700;
    }

    .totalWaterCanDetails .unitOfWaterCan {
        font-size: 25px;
        color: var(--loginBackColor) !important;
        font-weight: 700;
    }

    /* history visit Section */
    .historyVisitOuterSection {
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 1rem;
    }
    .historyVisitOuterSection .historyVisitInnerCard {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        box-shadow: 0 0 27px -12px gray;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }

    .historySecInnerCardLeft {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
    }


    .historySecInnerCardLeft .historySecUserName {
        font-size: 26px;
    }

    .historySecInnerCardLeft .historyVisitBtn {
        text-decoration: none;
        font-size: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 6px;
        color: var(--commonColor);

    }

    .historySecInnerCardImg {
        width: 30%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .historySecInnerCardImg img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    /* get help button */
    .getHelpHomePageSection {
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: flex-start; */
        gap: 1rem;
    }

    .getHelpHomePageSection .getHelpInnerCard {
        text-decoration: none;
        color: gray;
        width: 100%;
        height: 4rem;
        box-shadow:0 0 27px -12px gray;
        border-radius: 6px;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 0.8rem;

    }
    .getHelpHomePageSection .getHelpInnerCard span{
        font-size: 23px;
        font-weight: 700;
    }
}