.listcard {
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 27px -12px gray;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
}
.listCardDateTime {
  width: 100%;
  text-align: left;
  color: var(--commonColor);
  font-weight: 600;
}

.listCardUserSec {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
}

.listCardDescription {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: var(--commonColor);
  font-weight: 600;
}
.address {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: black;
  font-weight: 600;
}
.listCardRedirector {
  position: absolute;
  width: 100%;
  height: 100%;
}