.customersPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.customerPageBody {
    width: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    /* border: 2px solid red; */
}

.userNotFound {
    /* border: 2px solid red; */
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userNotFound img {
    height: 17rem;
    margin-bottom: 2rem;
}

.userNotFound p {
    color: #80808085;
    font-size: 1.5rem;
    font-weight: 700;
}