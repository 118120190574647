@media (max-width:450px) {
    .signupForm {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        margin-bottom: 2rem;
        padding: 0 1rem;
        padding-top: 1rem;
    }

    .signupQuote {
        font-size: 14px;
        width: 94%;
    }

    .formError {
        width: 94%;
        font-size: 12px;
        color: red;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }

    .termCondition {
        width: 94%;
        display: inline-flex;
        align-items: flex-start;
        justify-items: center;
        gap: 1rem;
    }

    .termCondition a {
        color: var(--loginBackColor);
    }

    .signupSubmitBtn {
        width: 94%;
        height: 3rem;
        font-size: 17px;
        background-color: var(--loginBackColor);
        color: #ffffff;
        border-radius: 6px;
        border: none;
        outline: none;

    }
    .submitBtn:disabled{
        background-color: gray !important;
        cursor: not-allowed;
        color: white;
    }
}