.duePaymentsCardSec {
    width: 100%;
    padding: 0 1rem;
}

.duePaymentsCardBody {
    box-shadow: 0 0 27px -12px gray;
    padding: 1rem;
    background-color: #0077FF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.duePaymentHeader{
    width: 100%;
    text-align: center;
    color: white;
    font-size: 15px;
}

.duePaymentsBody {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 1rem;

}

.duecard {
    width: calc(100%/2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

}

.dueAmmount {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 0.5rem;
    padding: 1rem 0;
    background-color: white;
    border-radius: 6px;

}
.duecard .dueTitle{
    color: white;
    font-size: 15px;
    font-weight: 600;
}
.dueAmmount span:first-child {
    font-size: 33px;
    color: #0077FF;
    font-weight:600;
}

.dueAmmount span:last-child {
    font-size: 25px;
    color: #0077FF;
    font-weight:600;

}
.duePaymentLink{
    text-decoration: none;
    color: white;
}