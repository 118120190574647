.editableInputSec {
    width: 100%;
    /* border: 2px solid red; */
    position: relative;
    padding: 1rem 0;
}

.editableInput {
    width: 100%;
    height: 2.5rem;
    border: none;
    border-bottom: 2px solid black;
    outline: none;
    padding: 3px;
    color: black;
    font-weight: 600;
    font-size: 15px;
    padding-right: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.editableInputSec label {
    position: absolute;
    top: -1px;
    left: 0;
    font-size: 13px;
    font-weight: 600;
    color: var(--commonColor);
}

.editableBtn {
    width: fit-content;
    height: fit-content;
    border: none;
    outline: none;
    background: transparent;
    position: absolute;
    right: 0;
    top: 30%;

}

.editableSecBtn {

    width: fit-content;
    height: fit-content;
    border: none;
    outline: none;
    background: transparent;
}

.editableInputDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 3px;
    border: none;
    border-bottom: 2px solid black;
    color: black;
}

.unEditAble {
    color: var(--commonColor);
    border-bottom: 2px solid var(--commonColor);
}