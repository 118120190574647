.mantine-bsiqi3 {
    top: 10rem;
}

.mantine-1abbsss {
    border-radius: 20px;
}

.successfullScannedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.successfullScannedContainer p {
    color: #004CA3;
    font-weight: 700;
}

.successfullScannedContainer button {
    align-items: center;
    background: #004CA3;
    border: none;
    border-radius: .5rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-self: center;
    height: 3rem;
    width: 80%;
    margin-top: 20px;
}