.duePaymentsPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.duePaymentsPageBody {
    width: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 1.5rem;
}

/* due card styling */

.duesPageCard {
    width: 100%;
    /* border: 2px solid red; */
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    box-shadow: 7px 10px 27px -15px rgba(128, 128, 128, 0.738), -9px -11px 44px -33px rgba(128, 128, 128, 0.738);
    overflow: hidden;
}

.duesPageHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 2px solid var(--loginBackColor);
    color: var(--loginBackColor);
    font-weight: 600;
}

.supplierClientSec {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.userNameAddress {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.userNameAddress span:first-child,
.userId {
    font-weight: 600;
}

.ammountAndWaterQuantity {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    /* width: 59%; */
}

.duesAmount,
.waterCanQuantity {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 0.4rem;
}

.duesAmount span,
.waterCanQuantity span {
    color: var(--loginBackColor);
    font-size: 25px;
}

.duesAmount span:first-child,
.waterCanQuantity span:first-child {
    font-size: 34px;

}

.ammountAndBtn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.paidActionBtn {
    padding: 1rem 2.5rem;
    border-radius: 6px;
    background-color: var(--loginBackColor);
    flex: 1;
    color: white;
    border: none;
    outline: none;
    font-size: 16px;
}

.blankMsgSec {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #80808085;
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blankMsgSec img{
    height: 15rem;
    margin-bottom: 2rem;
}