@media (max-width:450px) {
    .qrScannerPage {
        width: 100%;
        height: 95vh;
        background-color: rgb(54 54 54);
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 2rem;
        /* position: fixed; */
        /* top: 0;
        left: 0; */
    }
    .camView{
        max-width: 18rem;
        height: 17rem;
        border: 2px solid white;
        border-radius: 12px;
        min-width: 83%;
        background-color: transparent;
        object-fit: cover;
    }
    .scannerMsgBox{
        width: 100%;
        text-align: center;
        color: red;
    }
    .scannerCloser{
        width: 100%;
        padding: 0 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        color: white;
        font-size: 20px;
    }
    .scannerCloser{
        width: fit-content;
        height: fit-content;
        background: transparent;
        outline: none;
        border: none;
    }
    .backtoHomeBtn{
        padding: 0.5rem 1rem;
        color: white;
        background-color: var(--commonColor);
        border-radius: 6px;
    }
}