@media (max-width:450px) {
    .notificationPage {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .notificationPageBody {
        width: 100%;
        flex: 1;
        padding: 0 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
    }

    .notificationMarkBtnSec {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
    }

    .notificationAllReadBtn {
        width: fit-content;
        background: none;
        color: var(--commonColor);
        font-size: 15px;
        border: none;
        outline: none;
    }





}