.perDayStatusCardSec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
}

.perDayStatusCardBody {
       width: 100%;
    height: fit-content;
    box-shadow: 0 0 27px -12px gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    gap: 1rem;
}

.perDayStatusCardHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--commonColor);
    color: gray;
    font-weight: 600;
}

.perDayStatusCardHeader {
    padding-bottom: 6px;
}

.perDayStatusCardCounter {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.mesurementCard {
    width: calc(100% / 2);
    /* height: 3rem; */
    height: fit-content;
    background-color: #D6E9FF;
    color: #0077FF;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    border-radius: 7px;
    gap: 0.5rem;
    padding: 0.5rem 0;
}

.mesurementCard .measureNumber {
    font-size: 45px;
    font-weight: 600;
}
.mesurementCard .measureUnit {
    font-size: 24px;
}