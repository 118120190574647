.filterWaterDetails {
    width: 100%;
    padding: 0 1rem;
    /* border: 2px solid red; */
}

.filterWaterDetailsInnerSec {
    border-radius: 6px;
    padding: 1rem;
    box-shadow: 0 0 27px -12px gray;
    display: flex;
    flex-direction: column;

    align-items: center;
    gap: 1rem;

}

.monthViewLabel {
    font-size: 19px;
    color: gray;
    font-weight: 700;

}

.cardDateFilterHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--loginBackColor);
}

.cardDateFilterHeader .filterDate {
    position: relative;
    height: 3rem;
    width: 3rem;
}

.cardDateFilterHeader .filterDate #filterDate {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    z-index: 3;
}


.dateToggleBtn {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;
    outline: none;
    border: none;

}




.measurementCards {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2;
    place-items: center;
    gap: 1.5rem;
    overflow: hidden;
    --border-color: #0077FF;
    position: relative;

}


.measurementCards::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1.5px;
    background-color: var(--border-color);
    z-index: 1;
    transform: rotateZ(90deg);
}

.measurementCards .mesurementCard {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    gap: 0.5rem;
    background-color: #D6E9FF;
    color: var(--border-color);
    border-radius: 6px;
    width: 100%;
    height: 100%;
}

.measurementCards .mesurementCard .measureNumber {
    font-size: 45px;
    font-weight: bold;
}

.measurementCards .mesurementCard .measureUnit {
    font-size: 28px;
}

.profileUpdateInputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
}
